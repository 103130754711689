.trade-actions {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.trade-actions .bottom-ting {
	padding-left: 2rem;
}

.their-inventory, .my-inventory {
	flex-basis: 50%;
	flex-grow: 0;
	flex-shrink: 0;
}

.bottom-text-left {
	bottom: -1.5rem;
    left: 0.4rem;
    position: absolute;
    background: var(--black);
}

.partner-trade-title, .inventory-trade-title {
	position: absolute;
	top: -3rem;
	margin: 0;
}

.trade-actions .menu-container {
	overflow: hidden;
}

.partner-trade-menu-container, .inventory-trade-menu-container {
	height: calc(100%);
    overflow: hidden;
}

.trade-actions .top-ting {
	padding-bottom: 1.4rem;
}

.inventory-trade-menu-container .marked-trade {
	float: right;
	background: initial;
	color: var(--primary-light);
}

.under-text-right {
    float: right;
    position: relative;
    top: 2.2rem;
}